import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { withQuoteWizard } from '../components/withQuoteWizard';
import SEO from '../components/SEO';
import ProjectLayout from '../sections/ProjectLayout/ProjectLayout';
import ProjectHighlight from '../sections/ProjectHighlight/ProjectHighlight';
import ProjectFeature from '../sections/ProjectFeature/ProjectFeature';
import ProjectTransformation from '../sections/ProjectTransformation/ProjectTransformation';

//Images
import {GiffinParkImages, SingleProjectsIcons} from '../imageURLs';
import SimilarProjects from '../sections/SimilarProjects/SimilarProjects';
import { getElements } from '../helpers/functions';
import { similarProjects } from '../helpers/consts';


const pageData = {
  heroImage: GiffinParkImages.hero,
  projectTitle: 'Giffin Park, Coorparoo',
  projectSubtitle: "Brisbane City Council",
  serviceShortInfo: [
    {
      icon: SingleProjectsIcons.icon1,
      type: "Service",
      text: "Reconstruction"
    },
    {
      icon: SingleProjectsIcons.icon2,
      type: "Type",
      text: "AFL Field"
    },
    {
      icon: SingleProjectsIcons.icon3,
      type: "Turf type",
      text: "TifTuf"
    },
    {
      icon: SingleProjectsIcons.icon4,
      type: "Handover",
      text: "March 2022"
    },
    {
      icon: SingleProjectsIcons.icon5,
      type: "Value",
      text: "$650k"
    },
  ],
  serviceDescription: 'A sports field reconstruction completed with state-of-the-art GPS guided technology to reshape the field profile.',
  serviceListItems: [
    {
      value: '18,500m2',
      text: 'of premium TifTuf sports turf'
    },
    {
      value: '2,390m3',
      text: 'of premium sports blend top soil'
    },
    {
      value: '480lm',
      text: 'of perimeter fencing'
    },
    {
      value: '105t',
      text: 'of contaminated soil disposal'
    },
  ],
  featureImage: GiffinParkImages.feature,
  keyProcesses: [
    {
      title: "Management Plans",
      desc: "The former landfill site required site specific environmental plans including an ESCP, CLMP and asbestos removal control plans."
    },
    {
      title: "Site Establishment",
      desc: "Field was fenced, surveys conducted, environmental protection controls installed and goal posts removed."
    },
    {
      title: "Ground Preparation",
      desc: "Existing grass was killed off using multiple herbicide applications, then removed with a topmaker followed by the application of soil amendments."
    },
    {
      title: "Irrigation",
      desc: "Audit and service of irrigation system, including upgrades to valves and valve boxes along with the replacement of all sprinkler heads."
    },
    {
      title: "Final Levels",
      desc: "Importation and GPS laser grading of sports blend top soil to achieve the specified shape and grade."
    },
    {
      title: "Turf Installation",
      desc: "Supply and installation of premium TifTuf sports turf, which was rolled then treated with OxaMax fertilizer and pre emergent."
    },
    {
      title: "Maintenance",
      desc: "Intensive maintenance program consisting of mowing, top dressing, aerating, fertilising, pest and weed control, including regular irrigation monitoring."
    },
    {
      title: "Finishes",
      desc: "State AFL specification goal posts were installed, along with a new perimeter fence and field line marking."
    }
  ],
  featureTitle: 'Premium Turf Installation',
  featureHighlights: [
    "Premium sports turf variety renowned for its high performance",
    "Smart Approved WaterMark for exceptional drought tolerance to minimise water costs",
    "A dense, compact growth habit that offers high wear resistance and self recovery",
    "Can be managed with less than 66% input compared to couch varieties",
  ],
  featureThumbnail: GiffinParkImages.thumb,
  transformationImages: [
    {
      imagePath: GiffinParkImages.slide1
    },
    {
      imagePath: GiffinParkImages.slide2
    },
  ],
  similarProjects: getElements(similarProjects, [1,2,3,4,5,6,7,8])
}

const GiffinParkPage = () => {
  return (
    <React.Fragment>
      <SEO
        title="Giffin Park - Twin View Turf - Producer of Australia’s Best Lawns"
        keywords={[
          `Turf care guides`,
          `care guides`,
          `Turf`,
          `Lawns`,
          `Best Lawns`,
          `Australia’s Best Lawns`,
          `lawn producer`,
        ]}
      />
      <ProjectLayout>
        <ProjectHighlight 
          heroImage={pageData?.heroImage} 
          projectTitle={pageData.projectTitle} 
          projectSubtitle={pageData.projectSubtitle} 
          serviceShortInfo={pageData.serviceShortInfo}
          serviceDescription={pageData.serviceDescription} 
          serviceListItems={pageData.serviceListItems}
         />
        <ProjectFeature 
          featureImage={pageData?.featureImage}
          keyProcesses={pageData?.keyProcesses} 
          featureTitle={pageData?.featureTitle}
          featureHighlights={pageData.featureHighlights} 
          featureCtaHref={'/sports-turf'} 
          featureThumbnail={pageData?.featureThumbnail} />
        <ProjectTransformation 
          transformationSlider={pageData.transformationImages}
         />
         <SimilarProjects cards={pageData.similarProjects} />
      </ProjectLayout>
    </React.Fragment>
  )
}

export default withQuoteWizard(withStyles({ withTheme: true })(GiffinParkPage));